import { yup } from "../lib";

import { phoneRegExp } from "./formUtils";

export const CREATE_AGENCY_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("Agency Name is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  address: yup.string().required("Agency Address is required"),
  zip: yup.string().required("Zip code is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  active: yup.boolean().nullable().required("Select agency status"),

  first_name: yup.string().when("isEditAgency", {
    is: false,
    then: yup.string().required("First Name is required"),
  }),
  last_name: yup.string().when("isEditAgency", {
    is: false,
    then: yup.string().required("Last Name is required"),
  }),
  email: yup.string().when("isEditAgency", {
    is: false,
    then: yup.string().email("Enter a valid email").required("Email is required"),
  }),
  invoice_prefix: yup
    .string()
    .required("Enter a valid invoice prefix")
    .uppercase()
    .min(3, "Invoice prefix must be at least 3 characters long")
    .max(4, "Invoice prefix must be at most 4 characters long")
    .matches(/^[A-Z]+$/, "Invoice prefix must be uppercase letters"),
  logo: yup.string().required("Logo is required"),
});

export const EDIT_FACILITY_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("Facility Name is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  address: yup.string().required("Facility Address is required"),
  zip: yup.string().required("Zip code is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  active: yup.boolean().nullable().required("Select facility status"),
  appointments_per_hour: yup.number().nullable().required("Appointments per hour is required"),
  region_id: yup.number().required("Region is required"),

  // TODO proper hours validation once all API done
  // hours: yup.object({}),
  logo: yup.string().required("Logo is required"),
  facility_description: yup
    .string()
    .required("Facility description is required")
    .test("len", "Description must be less than 100 characters", (val) => val.length <= 100),
  facility_type: yup.string().required("Facility type is required"),
});

export const CREATE_FACILITY_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("Facility Name is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  address: yup.string().required("Facility Address is required"),
  zip: yup.string().required("Zip code is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  active: yup.boolean().nullable().required("Select facility status"),
  appointments_per_hour: yup.number().nullable().required("Appointments per hour is required"),
  region_id: yup.number().required("Region is required"),

  // TODO proper hours validation once all API done
  // hours: yup.object({}),

  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  logo: yup.string().required("Logo is required"),
  facility_description: yup
    .string()
    .required("Facility description is required")
    .test("len", "Description must be less than 100 characters", (val) => val.length <= 100),
  facility_type: yup.string().required("Facility type is required"),
});

export const CREATE_REGION_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("Region Name is required"),
});

export const CREATE_AGENCY_RATE_CODES_VALIDATION_SCHEMA = yup.object({
  contract: yup.string().required("Contract is required"),
  rateCodes: yup
    .array()
    .of(
      yup.object({
        active: yup.boolean(),
        description: yup.string().when("active", {
          is: true,
          then: yup.string().required("Description is required"),
        }),
        form_location: yup
          .string()
          .nullable()
          .when("form_required", {
            is: true,
            then: yup.string().nullable().required("File is required"),
          }),
        rate: yup.number().when("active", {
          is: true,
          then: yup
            .number()
            .transform((value, originalvalue) => Number(originalvalue))
            .required("Charge is required"),
        }),
      }),
    )
    .test({
      message: "Setup at least one Rate Code",
      test: (rateCodes) => !!rateCodes?.map((code) => code.active).filter(Boolean).length,
    })
    .min(1, "Setup at least one Rate Code"),
});

export const CREATE_APPOINTMENT_PATIENT_VALIDATION_SCHEMA = yup.object({
  patientType: yup.string().oneOf(["new", "existing"]),

  first_name: yup.string().when("patientType", {
    is: "new",
    then: yup.string().required("First Name is required"),
  }),
  last_name: yup.string().when("patientType", {
    is: "new",
    then: yup.string().required("Last Name is required"),
  }),
  phone: yup.string().when("patientType", {
    is: "new",
    then: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
  }),
  email: yup.string().when("patientType", {
    is: "new",
    then: yup.string().nullable().email("Enter a valid email"),
  }),
  date_of_birth: yup.date().when("patientType", {
    is: "new",
    then: yup.date().required("Date Of Birth is required"),
  }),
  mrn: yup.number().nullable().typeError("MRN must be a number"),
  // employee_id: yup
  //   .number().nullable()
  //   .typeError('Employee Id must be a number'),
  insured: yup
    .boolean()
    .nullable()
    .when("patientType", {
      is: "new",
      then: yup.boolean().nullable().required("Select is caregiver insured"),
    }),
  payer: yup.string().oneOf(["insurance", "patient", "agency"]).required("Payment method required"),
  patient_id: yup
    .number()
    .nullable()
    .when("patientType", {
      is: "existing",
      then: yup.number().nullable().required("Caregiver is required"),
    }),
});

export const CREATE_APPOINTMENT_SERVICES_VALIDATION_SCHEMA = yup.object({
  appointment_rate_codes: yup
    .array()
    .nullable()
    .min(1, "Select at least one service")
    .required("Select at least one service"),
  bundles: yup.array().nullable().max(1, "Only one bundle can be selected"),
});

export const CREATE_APPOINTMENT_TIME_VALIDATION_SCHEMA = yup.object({
  location: yup.number().required("Select Location"),
  facility: yup.string().required("Select Facility"),
  timeframe: yup.string().required("Select time slot"),
});
export const SCHEDULE_APPOINTMENT_TIME_VALIDATION_SCHEMA = yup.object({
  appointment_id: yup.number().required("Appointment Id"),
  location: yup.number().required("Select Location"),
  facility: yup.string().required("Select Facility"),
  timeframe: yup.string().required("Select time slot"),
});

export const CREATE_PATIENT_VALIDATION_SCHEMA = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  email: yup.string().nullable().email("Enter a valid email"),
  date_of_birth: yup.string().required("Date Of Birth is required"),
  mrn: yup.number().nullable().typeError("MRN must be a number"),
  // employee_id: yup
  //   .number().nullable()
  //   .typeError('Employee Id must be a number'),
  notes: yup.string().nullable(),
});

export const CREATE_RATE_CODE_VALIDATION_SCHEMA = yup.object({
  code: yup.string().required("Rate code is required"),
  description: yup.string().required("Description is required"),
  default_rate: yup.number().nullable().required("Default price is required"),
  form_required: yup.boolean().nullable().required("Select is form required"),
  form_location: yup.string().nullable(),
});
export const CREATE_DEFAULT_RATE_CODE_VALIDATION_SCHEMA = yup.object({
  code: yup.string().required("Rate code is required"),
  description: yup.string().required("Description is required"),
  default_rate: yup.number().nullable().required("Default price is required"),
  provider_cost: yup.number().nullable(),
  form_required: yup.boolean().nullable().required("Select is form required"),
  include_appt_limit: yup.boolean().nullable().required("Select if included in appointment limit"),
  form_location: yup.string().nullable(),
});
export const CREATE_DEFAULT_BUNDLE_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("Bundle name is required"),
  default_rate: yup.number().nullable().required("Default price is required"),
});
