// TODO add types and interfaces
export const AGENCY_TYPE = "home_health_agency";
export const FACILITY_TYPE = "medical_facility";

export interface PatientInitialValues {
  id?: number;
  home_health_agency_id: number | null;
  first_name: string;
  last_name: string;
  phone: string;
  date_of_birth?: string | null;
  insured: boolean | null;
  notes?: string | null;
  latest_appointment_status_id?: number | null;
  email: string | null;
  mrn: string | null;
  gender?: string | null;
  language: "english" | "spanish" | "russian" | "french_creole" | "italian" | "hindi" | "bengali";
  // employee_id?: string | null;
}

export interface AppointmentPatientInitialValues {
  id?: number;
  first_name: string;
  last_name: string;
  phone: string;
  date_of_birth?: string | null;
  insured: boolean | null;
  notes: string | null;
  patientType?: string;
  patient_id: number | null;
  mrn: string | null;
  email: string | null;
  gender?: string | null;
  language: "english" | "spanish" | "russian" | "french_creole" | "italian" | "hindi" | "bengali";
  payer?: string | null;
  // employee_id?: string | null;
}

export interface AgencyInitialValues {
  id?: number | string;
  type: string;
  name: string;
  phone: string;
  zip: string;
  state: string;
  city: string;
  address: string;
  active: boolean | null;
  first_name: string;
  last_name: string;
  email: string;
  logo: string;
  invoice_prefix: string;
  monthly_appointment_limit: number | null;
  monthly_appointments_scheduled: number | null;
  allows_unscheduled_appts: boolean;
  accepts_insurance: boolean;
  accepts_patient_payments: boolean;
  accepts_agency_payments: boolean;
}

export interface AppointmentTimeInitialValues {
  location: any;
  facility: number | null;
  timeframe: string;
  appointment_status_id?: number | null;
}

export interface CreateAppointmentServicesInitialValues {
  services?: any[] | null;
  appointment_rate_codes: any[] | null;
  bundles: any[] | null;
}

export interface AppointmentServicesInitialValues {
  rateCodes: any[] | null;
}

export interface AgencyRateCodesInitialValues {
  contract: string;
  rateCodes: any[];
  bundles: any[];
}

export interface HoursType {
  open: string;
  close: string;
}

export interface FacilityHoursType {
  sun: HoursType;
  mon: HoursType;
  tue: HoursType;
  wed: HoursType;
  thu: HoursType;
  fri: HoursType;
  sat: HoursType;
}

export interface LabsType {
  name: string;
  number: string;
  email: string;
}

export interface FacilityInitialValues {
  appointments_per_hour: number | string;
  region_id: number;
  id?: number | string;
  type: string;
  name: string;
  phone: string;
  zip: string;
  state: string;
  city: string;
  address: string;
  note: string;
  facility_description: string;
  facility_type: string;
  timezone: string;
  active: boolean | null;
  first_name: string;
  last_name: string;
  email: string;
  logo: string;
  hours: FacilityHoursType;
}

export interface RegionsType {
  name: string;
}

export const CREATE_PATIENT_INITIAL_VALUES: PatientInitialValues = {
  home_health_agency_id: null,
  first_name: "",
  last_name: "",
  phone: "",
  date_of_birth: "",
  insured: false,
  notes: null,
  email: "",
  mrn: null,
  gender: null,
  language: "english",
  // employee_id: null
};

export const CREATE_APPOINTMENT_PATIENT_INITIAL_VALUES: AppointmentPatientInitialValues = {
  first_name: "",
  last_name: "",
  phone: "",
  date_of_birth: "",
  insured: false,
  notes: "",
  patientType: "new",
  patient_id: null,
  email: "",
  mrn: null,
  gender: null,
  language: "english",
  payer: null,
  // employee_id: null,
};

export const CREATE_APPOINTMENT_SERVICES_INITIAL_VALUES: CreateAppointmentServicesInitialValues = {
  appointment_rate_codes: null,
  bundles: [],
};

export const CREATE_APPOINTMENT_TIME_INITIAL_VALUES: AppointmentTimeInitialValues = {
  location: "",
  facility: null,
  timeframe: "",
};

export const CREATE_AGENCY_INITIAL_VALUES: AgencyInitialValues = {
  type: AGENCY_TYPE,
  name: "",
  phone: "",
  zip: "",
  state: "",
  city: "",
  address: "",
  active: true,
  first_name: "",
  last_name: "",
  email: "",
  logo: "",
  invoice_prefix: "",
  monthly_appointment_limit: null,
  monthly_appointments_scheduled: null,
  allows_unscheduled_appts: false,
  accepts_insurance: false,
  accepts_patient_payments: false,
  accepts_agency_payments: false,
};

export const CREATE_AGENCY_RATE_CODES_INITIAL_VALUES: AgencyRateCodesInitialValues = {
  contract: "",
  rateCodes: [],
  bundles: [],
};

export const CREATE_FACILITY_INITIAL_VALUES: FacilityInitialValues = {
  type: FACILITY_TYPE,
  name: "",
  phone: "",
  zip: "",
  state: "",
  city: "",
  address: "",
  note: "",
  facility_description: "LevelUp Urgent Care",
  facility_type: "check-in",
  timezone: "America/Detroit",
  active: null,
  appointments_per_hour: "",
  region_id: 1,
  // printer: "",
  first_name: "",
  last_name: "",
  email: "",
  logo: "",
  hours: {
    sun: {
      open: "",
      close: "",
    },
    mon: {
      open: "",
      close: "",
    },
    tue: {
      open: "",
      close: "",
    },
    wed: {
      open: "",
      close: "",
    },
    thu: {
      open: "",
      close: "",
    },
    fri: {
      open: "",
      close: "",
    },
    sat: {
      open: "",
      close: "",
    },
  },
};

export const LABS_INITIAL_VALUES: LabsType = {
  name: "",
  number: "",
  email: "",
};

export const CREATE_REGION_INITIAL_VALUES: RegionsType = {
  name: "",
};

export interface RateCodeInitialValues {
  id?: number;
  code: string;
  description: string;
  default_rate: number | null;
  provider_cost: number | string;
  form_required: boolean | null;
  include_appt_limit: boolean | null;
  form_location?: string | null;
}
export interface BundleInitialValues {
  id?: number;
  name: string;
  default_rate: number | null;
  form_required: boolean | null;
  filenames_id?: string | null;
  bundle_rate_codes: any;
  rateCodes: any[];
}

export interface EditRateCodeInitialValues {
  id?: number;
  active: boolean | null;
  code: string;
  description: string;
  rate: number | null;
}

export const CREATE_RATE_CODE_INITIAL_VALUES: RateCodeInitialValues = {
  code: "",
  description: "",
  default_rate: null,
  provider_cost: "",
  form_required: null,
  include_appt_limit: null,
  form_location: null,
};
export const CREATE_BUNDLE_INITIAL_VALUES: BundleInitialValues = {
  name: "",
  default_rate: undefined,
  form_required: false,
  filenames_id: null,
  bundle_rate_codes: [],
  rateCodes: [],
};

export const EDIT_RATE_CODE_INITIAL_VALUES: EditRateCodeInitialValues = {
  active: null,
  code: "",
  description: "",
  rate: null,
};
