import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, FormHelperText, Grid, TextField, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import FileUpload from "../FileUpload";
import { Form, Navigation, useFormik } from "../../../lib";
import {
  useAction,
  superAdminActions,
  useSelector,
  superAdminSelectors,
  useDispatch,
} from "../../../state";
import { CREATE_RATE_CODE_INITIAL_VALUES as initialValues } from "../../../constants";
import { CREATE_DEFAULT_RATE_CODE_VALIDATION_SCHEMA as validationSchema } from "../../../utils";
import { useStyles } from "./DefaultRateCodeForm.styles";

const DefaultRateCodeForm = () => {
  const classes = useStyles();
  const { id }: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const createRateCode = useAction(superAdminActions.createDefaultRateCode);
  const updateRateCode = useAction(superAdminActions.updateDefaultRateCode);
  const rateCode = useSelector(superAdminSelectors.defaultRateCode);
  const isEdit = Boolean(id);
  const values = isEdit ? rateCode : initialValues;

  useEffect(() => {
    if (id) {
      dispatch(superAdminActions.getDefaultRateCode(id));
    }
  }, [dispatch, id]);

  const form = useFormik({
    initialValues: {
      ...initialValues,
      ...values,
    },
    enableReinitialize: true,

    validationSchema,
    async onSubmit(values) {
      const action = isEdit ? updateRateCode : createRateCode;
      await action(values);
      Navigation.go("/super-admin/default-rate-codes");
    },
  });

  const handleSwitcherChange = useCallback(
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        form.setFieldValue(fieldName, value);
      }
    },
    [form],
  );

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Form form={form} className={classes.form}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.controlsWrap}>
          <Grid container wrap="nowrap">
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="code"
                name="code"
                label="Rate code"
                value={form.values.code}
                onChange={form.handleChange}
                error={form.touched.code && Boolean(form.errors.code)}
                helperText={form.touched.code && form.errors.code}
              />
            </Grid>
            <Grid item className={clsx(classes.formControl, classes.description)}>
              <TextField
                fullWidth
                variant="outlined"
                id="description"
                name="description"
                label="Description"
                value={form.values.description}
                onChange={form.handleChange}
                error={form.touched.description && Boolean(form.errors.description)}
                helperText={form.touched.description && form.errors.description}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="default_rate"
                type="number"
                name="default_rate"
                label="Default Price"
                value={form.values.default_rate ?? ""}
                onChange={form.handleChange}
                error={form.touched.default_rate && Boolean(form.errors.default_rate)}
                helperText={form.touched.default_rate && form.errors.default_rate}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="provider_cost"
                type="number"
                name="provider_cost"
                label="Cost price"
                value={form.values.provider_cost ?? ""}
                onChange={form.handleChange}
                error={form.touched.provider_cost && Boolean(form.errors.provider_cost)}
                helperText={form.touched.provider_cost && form.errors.provider_cost}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className={classes.formControl}>
              <Box className={classes.sectionTitle}>
                <Typography>Form required</Typography>
              </Box>
              <ToggleButtonGroup
                className={classes.switcherGroup}
                exclusive
                size="large"
                value={form.values.form_required || ""}
                onChange={handleSwitcherChange("form_required")}
              >
                <ToggleButton
                  value={true}
                  selected={Boolean(form.values.form_required)}
                  className={classes.switcher}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  value={false}
                  selected={
                    // eliminate case when null is selected as "No" options
                    typeof form.values.form_required === "boolean" &&
                    !Boolean(form.values.form_required)
                  }
                  className={classes.switcher}
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>

              {Boolean(form.errors.form_required) && (
                <FormHelperText error variant="outlined">
                  {form.errors.form_required}
                </FormHelperText>
              )}
            </Grid>

            <Grid item className={classes.formControl}>
              <Box className={classes.sectionTitle}>
                <Typography>Include in appointment limit</Typography>
              </Box>
              <ToggleButtonGroup
                className={classes.switcherGroup}
                exclusive
                size="large"
                value={form.values.include_appt_limit || ""}
                onChange={handleSwitcherChange("include_appt_limit")}
              >
                <ToggleButton
                  value={true}
                  selected={Boolean(form.values.include_appt_limit)}
                  className={classes.switcher}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  value={false}
                  selected={
                    // eliminate case when null is selected as "No" options
                    typeof form.values.include_appt_limit === "boolean" &&
                    !Boolean(form.values.include_appt_limit)
                  }
                  className={classes.switcher}
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>

              {Boolean(form.errors.include_appt_limit) && (
                <FormHelperText error variant="outlined">
                  {form.errors.include_appt_limit}
                </FormHelperText>
              )}
            </Grid>
          </Grid>

          <Box className={classes.sectionTitle}>
            <Typography>Form upload</Typography>
          </Box>

          <Box className={classes.fileUploadWrap}>
            <FileUpload
              form={form}
              name="form_location"
              accept="application/pdf"
              uploadType="private"
            />

            {form.touched.form_location && Boolean(form.errors.form_location) && (
              <FormHelperText error variant="outlined">
                {form.errors.form_location}
              </FormHelperText>
            )}
          </Box>

          <Grid container justifyContent="center" className={classes.formActions} spacing={4}>
            <Grid item>
              <Button
                onClick={handleBackClick}
                variant="outlined"
                color="primary"
                size="large"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="large" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default DefaultRateCodeForm;
